<template>
	<div>
		<CRow>
			<CCol class="text-right">
				<router-link
					:to="{ name: 'CustomerGroupCreate' }"
					class="btn btn-secondary"
				>
					<CIcon name="cil-plus" class="mr-2" />
					Create group
				</router-link>
			</CCol>
		</CRow>
		<CRow class="mt-4">
			<CCol>
				<form
					class="search-form flex-fill"
					@submit.prevent="handleSearch"
				>
					<CInput
						v-model="queryParams.q"
						placeholder="Search by group name"
					>
						<template #prepend-content>
							<CIcon name="cil-magnifying-glass" data-test-id="search-button" />
						</template>
					</CInput>
				</form>
			</CCol>
			<!-- TODO: Wait for BE -->
			<!-- <CCol md="3">
				<BaseDropDown :options="[]" />
			</CCol> -->
		</CRow>
		<BaseTable
			:is-loading="list.isLoading"
			:fields="CUSTOMER_GROUP_FIELDS"
			:items="dataTable"
			:pagination="{
				pages: list.meta.lastPage,
				activePage: list.meta.currentPage
			}"
			striped
			class="table-custom"
			link-to="CustomerGroupEdit"
			@onPaginationClick="handlePageChange"
		>
			<template #no-items-view>
				<div class="empty-table-element">
					<p class="subtitle">
						{{ $t("global.searchNotFound", { field: "customer groups" }) }}
					</p>
				</div>
			</template>
			<template #status="{ item }">
				<CBadge
					:color="item.color"
					class="badge-status"
				>
					{{ item.name }}
				</CBadge>
			</template>
		</BaseTable>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { cleanObject, cloneDeep, nullableToDashFormat, numberFormat, randomSearchString } from '../assets/js/helpers';

export default {
	name: 'CustomerGroupList',
	data() {
		return {
			queryParams: {
				q: this.$route.query.q || null,
				page: Number(this.$route.query.page) || null,
				r: randomSearchString(),
			},
		};
	},
	computed: {
		...mapState('customerGroups', {
			list: 'list',
		}),
		CUSTOMER_GROUP_FIELDS() {
			return [
				{ key: 'name', label: 'Group name' },
				{ key: 'description', label: 'Description' },
				// TODO: Wait for API
				// { key: 'pricingType': label: 'Pricing type'},
				{ key: 'total', label: 'Total users' },
			];
		},
		dataTable() {
			return (this.list?.data || []).map((customerGroup) => ({
				id: customerGroup.id,
				name: customerGroup.name,
				description: nullableToDashFormat(customerGroup.description),
				total: numberFormat(customerGroup.total),
			}));
		},
	},
	async created() {
		await this.getCustomerGroupList(this.queryParams);
	},
	methods: {
		...mapActions({
			getCustomerGroupList: 'customerGroups/getCustomerGroupList',
		}),
		updateUrlParams() {
			const query = cleanObject(cloneDeep(this.queryParams));
			this.$router.push({ query }).catch(() => {});
		},
		handleSearch() {
			this.queryParams.page = null;
			this.updateUrlParams();
		},
		handlePageChange(page) {
			this.queryParams.page = page;
			this.updateUrlParams();
		},
	},
};
</script>
